.header {
  
  display: flex;
  flex-direction: row;
  list-style: none;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
  border-bottom: 1px solid black;
  font-size: 15px;
  color: grey;

  li {
    width: 25%;
  }
  li:last-child {
    width: 20%;
    display: flex;
    flex-direction: row;
    justify-content: center;
  }
}
